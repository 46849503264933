
import { defineComponent, inject } from 'vue'
import Swal from 'sweetalert2';
import { useI18n } from "vue-i18n";
import i18n from '@/i18n';

export default defineComponent({
    emits: ['loading', 'error', 'imagePath'],
    props: {
        ref: String
    },
    setup(props: any, {emit}) {
        const configurationStore: any = inject('configurationStore');
        const click =()=>{
            document.getElementById('file-input')?.click();
        }

        const validateImage = (image: any)=>{
            if (image.size > 6291456) {
                return false;
            }
            if(image.type != 'image/png' && image.type != 'image/jpg' && image.type != 'image/jpeg' && image.type != 'image/gif'){
                return false;
            }
            return true;
        }

        const uploadImage = async(event: any)=>{
            const fileInfo = event.target.files[0];
            if(!validateImage(fileInfo)){
                Swal.fire({
                        icon: 'warning',
                        title: i18n.global.t('tester_image_not_valid'),
                        text: i18n.global.t('tester_image_not_valid_msg')
                });
                return;
            }
            const file = new FormData();
            file.append('file', event.target.files[0], event.target.files[0].name);
            emit('loading', true);
            const imagePath = await configurationStore.uploadImage(file);
            if(!imagePath){
                emit('error');
                return;
            }
            emit('imagePath', imagePath);
            emit('loading', false);

        }

        return{
            uploadImage,
            click
        }
    },
})
